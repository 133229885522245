<template>
  <UnitsFrame class='pt-7'>
    <div
      class='section__text--title d-flex justify-space-between align-center mb-5'
    >
      Mis unidades
    </div>
    <v-row>
      <v-col
        class='mb-0'
        cols='12'
        sm='6'
        md='4'
      >
        <FlashcardItem
          :item='errorMazo'
          :canDelete="false"
        />
      </v-col>
      <v-col
        class='mb-0'
        cols='12'
        sm='6'
        md='4'
      >
        <FlashcardItem
          :item='mazoFavourite'
          :haveMenu="false"
        />
      </v-col>
      <v-col
        v-for='card in editableMazo'
        :key='card.id'
        class='mb-0'
        cols='12'
        sm='6'
        md='4'
      >
        <FlashcardItem
          :item='card'
        />
      </v-col>
      <v-col
        v-if="editableMazo.length<mazosLimit"
        cols='12'
        sm='6'
        md='4'
      >
        <AddFlashcardItem
          @click='createFlashcard'
        />
      </v-col>
    </v-row>
  </UnitsFrame>
</template>

<script>
import UnitsFrame from '@/components/units/UnitsFrame.vue'
import FlashcardItem from '@/components/mazos/FlashcardItem.vue'
import AddFlashcardItem from '@/components/mazos/AddFlashcardItem.vue'
import { mapActions, mapState } from 'vuex'

export default {
  components: { UnitsFrame, FlashcardItem, AddFlashcardItem },
  data: () => ({
    mazosLimit: 100,
    mazoFavourite: {
      created: '2022-03-08T10:22:18.537597-05:00',
      descripcion: 'Aquí están todas tus flashcards favoritas para acceso rápido, ¿Algún dato para tu internado? ¿Algún dato que sigues fallando? ¿Algún dato que quieres para acceso rápido? Aquí las encontrarás.',
      id: 100000,
      modified: '2022-03-08T10:22:18.537597-05:00',
      titulo: 'Favoritos'
    }
  }),
  computed: {
    ...mapState('myflashcards', ['mazoList']),
    errorMazo () {
      return this.mazoList.find(item => item.default)
    },
    editableMazo () {
      return this.mazoList.filter(item => !item.default)
    }
  },
  methods: {
    createFlashcard () {
      this.$router.push({ name: 'CreateMazo' })
    },
    ...mapActions('myflashcards', ['fetchMazoList'])
  },
  async created () {
    await this.fetchMazoList()
  }
}
</script>
