<template>
  <v-card
    class="d-flex flex-column justify-end align-start flashcard-item overflow-hidden"
    @click="goToSimulator()"
  >
    <FlashcardItemMenu v-if="haveMenu" :canDelete="canDelete" @doAction="doAction" />
    <div class="card__content d-flex flex-column justify-space-between">
      <div class="text-truncate-container-title">
        <p class="flashcard__text--body ma-0" v-text="item.titulo" />
      </div>
      <div class="d-flex justify-space-between text-truncate-container">
        <p p class="card__text pa-0 ma-0" v-text="item.descripcion" />
      </div>
    </div>
    <div class="footer__line" />
    <SuccessModal
      v-model="successMazoCreated"
      :loading="loading"
      :message="modal.message"
      :acceptMessage="modal.acceptMessage"
      :cancelMessage="modal.cancelMessage"
      @deleteMazo="deleteMazo"
      @showMazo="showMazo"
    />
  </v-card>
</template>

<script>
import FlashcardItemMenu from '@/components/mazos/FlashcardItemMenu.vue'
import SuccessModal from '@/components/mazos/SuccessModal.vue'
import { mapActions } from 'vuex'
import axios from 'axios'

export default {
  name: 'FlashcardItem',
  components: { FlashcardItemMenu, SuccessModal },
  props: {
    haveMenu: {
      type: Boolean,
      default: true
    },
    canDelete: {
      type: Boolean,
      default: true
    },
    item: {
      type: Object,
      default: function () {
        return {
          titulo: 'Primer mazo',
          id: null,
          descripcion: 'lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum',
          created: '2022-03-04T12:06:00.220671-05:00',
          modified: '2022-03-04T12:06:00.220671-05:00'
        }
      }
    }
  },
  data: () => ({
    successMazoCreated: false,
    modal: {
      message: '',
      acceptMessage: '',
      cancelMessage: ''
    },
    loading: false
  }),
  methods: {
    ...mapActions('myflashcards', ['fetchMazoList']),
    async goToSimulator () {
      if (!this.haveMenu) {
        this.$router.push({ name: 'FlashcardFavouriteSimulator' })
      } else {
        this.$router.push({ name: 'FlashcardSimulator', params: { tipo: 'mis-flashcards', ids: this.item.id } })
      }
    },
    doAction (index) {
      if (index === 2) {
        this.successMazoCreated = true
        this.modal = {
          message: 'Confirma que desea eliminar este mazo?',
          acceptMessage: 'Atrás',
          cancelMessage: 'Eliminar mazo'
        }
      } else {
        this.$router.push({ name: 'UpdateMazo', params: { id: this.item.id } })
      }
    },
    showMazo () {
      if (this.$route.name === 'UpdateMazo') {
        this.successMazoCreated = false
      } else {
        this.$router.push({ name: 'UpdateMazo', params: { id: this.item.id } })
      }
    },
    async deleteMazo () {
      this.loading = true
      try {
        await axios.delete(`api/flashcards/mis_mazos/${this.item.id}/`)
        await this.fetchMazoList()
      } catch (e) {
        this.$root.$confirm({
          title: 'Error',
          message: 'Ocurrio un error al eliminar el mazo. Por favor, intente más tarde.',
          acceptMessage: 'Ok'
        })
      } finally {
        this.loading = true
        this.successMazoCreated = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.padd {
  padding-left: 14px;
}
.card {
  &__content {
    padding: 30px;
    width: 100%;
    height: 100% !important;
  }
  &__text {
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: rgba(38, 38, 38, 0.5);
  }
  &__subtext {
    font-size: 14px;
    line-height: 21px;
  }
  &__detail {
    width: 64px;
    height: 19px;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 3px;
  }
}
.text-truncate-container {
  width: 100%
}
.text-truncate-container p {
  -webkit-line-clamp: 4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.text-truncate-container-title p {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media (max-width: 960px) {
  .card {
    &__text {
      font-size: 12px;
      line-height: 18px;
    }
  }
  .text-truncate-container p {
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
</style>
