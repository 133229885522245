<template>
  <v-menu
    rounded="lg"
    offset-y
  >
    <template #activator="{ attrs, on }">
      <v-btn
        class="white--text pa-0 ma-0 menu"
        v-bind="attrs"
        icon
        v-on="on"
      >
        <v-icon color="#1EB4D3">
          mdi-dots-vertical
        </v-icon>
      </v-btn>
    </template>

    <v-list class="pa-0">
      <v-list-item
        v-for="(item, index) in items"
        :key="index"
        class="menu__item"
        @click="$emit('doAction', item.id)"
      >
        <v-list-item-title
          class="menu__text"
          :class="item.id===2 ? 'menu__text--logout': ''"
          v-text="item.name"
        />
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    canDelete: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    items: [
      {
        id: 1,
        name: 'Ver y Editar Mazo'
      },
      {
        id: 2,
        name: 'Eliminar mazo'
      }
    ]
  }),
  mounted () {
    if (!this.canDelete) {
      this.items.pop()
    }
  }
}
</script>

<style lang="scss" scoped>
.menu {
  position: absolute !important;
  top: 10px;
  right: 1px;
  &__item {
    border-bottom: 1px solid #E5E5E5;
  }
  &__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #262626;
    &--logout {
      font-style: normal;
      font-weight: 500;
      color: #FF2E00;
    }
  }
}
</style>
