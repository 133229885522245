<template>
  <v-dialog
    v-bind="$attrs"
    max-width="550"
    v-on="$listeners"
  >
    <v-card
      rounded="lg"
      :height="smAndDown ? 'auto': '441'"
      class="py-3"
    >
      <div class="d-flex justify-center align-center">
        <v-img
          src="@/assets/my-flashcards/success.svg"
          :max-width="smAndDown ? '132': '215'"
          :height="smAndDown ? '132': '215'"
        />
      </div>
      <div class="text-center black--text px-4 dialog__message-wrapper" :class="smAndDown ? 'py-4': 'py-10'">
        <p class="dialog__message">
          {{ message }}
        </p>
      </div>
      <div class="pb-5 d-flex justify-center" :class="smAndDown ? 'flex-column': ''">
        <v-btn
          v-if="acceptMessage"
          class="white--text mx-auto dialog__btn text-capitalize"
          :width="cancelMessage ? smAndDown ? '90%': '239': smAndDown ? '90%': '326'"
          height="45"
          @click="$emit('showMazo')"
        >
          {{ cancelMessage !== '' ? 'Atrás': 'Ver mazo' }}
        </v-btn>
        <v-btn
          v-if="cancelMessage"
          class="white--text mx-auto dialog__btn dialog__btn--cancel text-capitalize"
          :class="smAndDown ? 'mt-4': ''"
          :width="smAndDown ? '90%': '239'"
          height="45"
          :loading="loading"
          @click="$emit('deleteMazo')"
        >
          Eliminar mazo
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    acceptMessage: {
      type: String,
      default: ''
    },
    cancelMessage: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    smAndDown () {
      return this.$vuetify.breakpoint.smAndDown
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog {
  &__message {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    letter-spacing: -0.24px;
    color: #616161 !important;
  }
  &__message-wrapper p {
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  &__btn {
    background: #12A2D6 !important;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 40px;
    &--cancel {
      background: #DA0505 !important;
    }
  }
}
@media (max-width: 960px) {
  .dialog {
    &__message {
      font-size: 14px;
      line-height: 25px;
    }
  }
}
</style>
