<template>
  <v-hover
    v-slot="{ hover }"
  >
    <div
      class="add d-flex flex-column justify-center align-center"
      @click="$emit('click')"
    >
      <v-icon size="100" :color="!hover ? '#E0E0E0': '#1EB4D3'">
        mdi-plus-thick
      </v-icon>
      <v-btn
        class="add__btn"
        :class="hover ? 'add__btn--hover': ''"
        height="40"
        :width="hover ? '190': '160'"
        elevation="0"
      >
        Clic para crear mazo
      </v-btn>
    </div>
  </v-hover>
</template>

<script>
export default {
}
</script>
<style lang="scss" scoped>
.add {
  // width: 282px;
  width: 100%;
  height: 202px;
  // height: 202px;
  border: 1.5px dashed #C9C9C9;
  // border-width: 2px;
  // border-style: dotted;
  box-sizing: border-box;
  border-radius: 15px;
  cursor: pointer;
  // &:before {
  //   content: "";
  //   width: 282px;
  //   height: 202px;
  //   border: 10px dashed #FF0000;
  // }
  &__btn {
    background-color: #E0E0E0 !important;
    border-radius: 5px;
    color: #ffffff;
    font-weight: bold;
    font-size: 12px;
    line-height: 30%;
    text-transform: none;
    transition: 0.1s ease;
    &--hover {
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      background: linear-gradient(270deg, #1EB4D3 0%, #1FC1D0 100%);
      border-radius: 40px;
    }
  }
}
@media (max-width: 960px) {
  .add {
    height: 174px;
  }
}
</style>
